<template>
  <main class="Stores">
    <TheSubHeader 
      icon="store-alt" 
      type="filter"
      title="Lojistas"
      page-title-tag="Stores"
      addnew-link="/stores/add"
      :actions="['addnew']"
      organizer-gap="1rem"
      organizer-columns="3fr 1fr"
    >
      <template #beforeButtons>
        <AsyncExportButton
          v-if="permissions && permissions.stores && permissions.stores.exportStoresButton"
          name="stores"
          name-report="Relatorio_de_Lojistas"
          icon="export"        
          color="dark"
          label="Exportar"
          label-loading="Exportando relatório"
          :track="trackSubheaderButtonString('Hubs', 'Export')"
        />
      </template>
      <template #filter>
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.searchValue"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.statusSelected"
          name="Status"
          label="Status"
          placeholder="Selecione"
          :clearable="false"
          :select-options="statusOpt"
          @input="resetSearch"
        />
      </template>
    </TheSubHeader>
    <div class="Stores__content">
      <div class="Stores__table">
        <DataTable
          :header="storesTable.data.headers"
          :table="storesTable.data.content"
          is-sticky
          @edit="editStores"
          @changeLocation="changeLocation"
          @download="downloadSpreadsheet"
          @enableAutoSyncRoutEasy="$refs.routEasySync.open($event, true)"
          @disableAutoSyncRoutEasy="$refs.routEasySync.open($event, false)"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>

    <LocationChange ref="location" :data="locationData" title="do lojista" @save="saveNewLocation" />
    <StoreRoutEasyToggleSyncModal ref="routEasySync" @save="saveSyncRoutEasy" />
  </main>
</template>

<script>
import { BaseTreeSelect } from '@/components/atoms'
import { DataTable, Pagination, LocationChange, AsyncExportButton } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState } from 'vuex'
import StoreRoutEasyToggleSyncModal from './StoreRoutEasyToggleSyncModal.vue'
import { stores } from '@/api'

export default {
  name: 'OrdersImport',
  components: {
    TheSubHeader,
    BaseTreeSelect,
    DataTable,
    Pagination,
    LocationChange,
    AsyncExportButton,
    StoreRoutEasyToggleSyncModal
  },
  
  data() {
    return {
      filters: {
        searchValue: [],
        statusSelected: { id: 0, name:'Todos'},
      },
      storeTransporters: {},
      currentPage: 1,

      statusOpt: [
        { id: 0, name:'Todos'}, 
        { id: 1, name:'Ativo' }, 
        { id: 2, name:'Inativo' }
      ],

      //Location Change
      locationData: {}
    }
  },
  
  computed:{
    ...mapState({
      permissions: state => state.auth.permissions,
      storesTable: state => state.stores.storesTable,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),
  },

  mounted(){
    this.search()
  },

  methods:{
    ...mapActions({
      fetchStoresTable: 'stores/fetchStoresTable',
      setParameters: 'queryString/setParameters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading'
    }),

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    search(){
      const params = {
        obj: {
          id: this.$refs.lojista.checkSelectAll ? [] : this.filters.searchValue,
          status_id: this.filters.statusSelected ? this.filters.statusSelected.id : null
        },
        paginate: this.paginate,
        page: this.page
      }

      this.setParameters(this.filters)
      this.fetchStoresTable(params)
        .then(() => this.setPagination(this.storesTable))
    },

    editStores(data){
      this.openInNewTab(`/stores/${data.id}`)
    },

    //Location Change
    changeLocation(data) {
      this.locationData = data
      this.$refs.location.open()
    },

    saveNewLocation(map) {   
      this.setLoading(true)
      stores.saveStoresLocation(map, () => {
        this.search()
      }, () => {this.setLoading(false)}, () => {})
    },

    downloadSpreadsheet(data) {
      this.setLoading(true)
      stores.downloadSpreadsheet(data.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    saveSyncRoutEasy(data) {
      let body = {
        auto_sync: data.toggle ? 1 : 0
      }
      this.setLoading(true)
      stores.syncRoutEasy(data.id, body, async () => {
        await this.search()
        this.$refs.routEasySync.close()
      }, (e) => e, () => {
        this.setLoading(false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Stores {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }

  &__calendarTitle{
    display: flex;
    align-items: center;
    color: $color-neutral-stronger;
    font-weight: $font-weight-bold;
    font-size: $font-size-1xsmall;
    margin-left: 5%;
    height: 100%;
  }

  &__calendarContainer{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
  }

  &__WorkingDaysTitle{
    color: $color-neutral-stronger;
    font-size: $font-size-large;
    margin-bottom: 10px;
  }

  &__WorkingDays{
    display: flex;
    justify-content: space-between;
  }

  &__day{
    width: 13%;
  }
  
  &__hr{
    width: 95%;
    background-color: $color-neutral-stronger;
    height: 2px;
    margin: 40px auto;
  }

  &__HolidaysTitle{
    color: $color-neutral-stronger;
    font-size: $font-size-large;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__Holiday{
    display: grid;
    grid-template-columns: 1fr 240px 1fr 1fr;
    align-items: flex-end;
    grid-gap: 20px;
    padding-bottom: 30px;
  }

  &__holidayDate{
    border-bottom: 2px solid $color-neutral-stronger;
    position: relative;
  }

  &__holidayDateLabel{
    margin-top: 10px;
    color: $color-neutral-stronger;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
    pointer-events: none;
  }

  &__calendarIcon{
    position: absolute;
    width: 15px;
    left: 0;
    bottom: 5px;
    pointer-events: none;
    z-index: $z-label;
  }

  &_calendarSave{
    position: sticky;
    width: 100%;
    bottom: -1px;
    left: 0;
    height: 50px;
    z-index: $z-nav;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-neutral-softest;
  }

  &__calendarSaveBtn{
     width: 50%;
  }
}
</style>