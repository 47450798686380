<template>
  <Modal v-if="modal" class="StoreRoutEasyToggleSync__Modal" @close="close">
    <template #header>
      <div class="StoreRoutEasyToggleSync__Modal__Header">
        <span>{{ storeName }}</span>
      </div>
    </template>
    <template #body>
      <div class="StoreRoutEasyToggleSync__Modal__Body">
        <p>Tem certeza que deseja {{ toggle ? "ativar" : "desativar" }} a sincronização dessa loja com o RoutEasy?</p>

        <div class="StoreRoutEasyToggleSync__Modal__Actions">
          <BaseButton
            label="Cancelar"
            filled
            color="dark"
            text-color="white"
            @click="cancel"
          />

          <BaseButton
            label="Salvar"
            filled
            color="dark"
            text-color="white"
            @click="save"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { BaseButton } from '@/components/atoms'
import { Modal } from '@/components/molecules'
export default {
  name: 'StoreRoutEasyToggleSyncModal',
  components: {
    BaseButton,
    Modal,
  },
  data() {
    return {
      id: null,
      modal: false,
      toggle: false
    }
  },
  methods: {

    //Handlers
    open(event, toggle) {
      this.id = event.id
      this.storeName = event.name
      this.toggle = toggle
      this.modal = true
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

    cancel() {
      this.modal = false
      this.$emit('cancel')
    },

    save() {
      this.$emit('save', {
        id: this.id,
        toggle: this.toggle
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.StoreRoutEasyToggleSync {
  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      min-width: 600px;
    }

    &__Header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

    &__Actions {
      display: flex;
      align-self: flex-end;
      margin-top: 2rem;

      /deep/ .BaseButton  {
        height: 25px;
        margin: 0 10px;

        &__Text {
          text-transform: capitalize;
          margin-left: 0;
        }

      }
    }

  }


}
</style>
